import React from "react";
import { t, getLanguage } from "../../utils/i18n";
import { Grid, Typography } from "@material-ui/core";

// Services
import skillsService from "../../services/skills.service";

export const fetchSkills = async (userfunctions) =>
  skillsService.list({
    filter: `filter=${JSON.stringify({
      $or: [{ userfunctions }, { "userfunctions.0": { $exists: false } }],
    })}`,
  });
export default function ResumeCandidateSkills({
  candidate = {},
  onClose,
  edit = false,
  error = false,
  helperText,
}) {
  candidate.skills = candidate.skills || [];
  const language = getLanguage();
  const [skills, setSkills] = React.useState(false);
  const [skillForm, setSkillForm] = React.useState(false);

  React.useEffect(() => {
    fetchSkills(candidate.userfunction[0]._id).then((response) => {
      const skills = response.records.map((skill) => {
        skill.skills = skill.skills.filter(
          (item) =>
            item.state !== "pending" || item.createdBy === candidate.userId
        );
        return skill;
      });
      setSkills(skills);
    });
    // eslint-disable-next-line
  }, [candidate.userfunction]);

  const getFilteredSkills = () => {
    const copy = JSON.parse(JSON.stringify(skills)) || [];
    return copy.map((skill) => {
      skill.skills = skill.skills.filter((x) => {
        return candidate.skills.map((cs) => cs.skillId).includes(x._id);
      });
      return skill;
    });
  };

  return (
    <ul className="functie-tags">
      <Grid>
        {!!candidate.skills.length ? (
          getFilteredSkills().map((skill) => {
            return (
              !!skill.skills.length && (
                <>
                  {skill.skills.map((item) => {
                    return (
                      <li>
                        <span className="function-which">
                          {item.label[language]}
                        </span>
                        <span className="rating">
                          {[
                            ...Array(
                              candidate.skills.find(
                                (skill) => skill.skillId === item._id
                              ).level
                            ),
                          ].map((star, n) => (
                            <span className="star full"></span>
                          ))}
                          {[
                            ...Array(
                              5 -
                                candidate.skills.find(
                                  (skill) => skill.skillId === item._id
                                ).level
                            ),
                          ].map((star, n) => (
                            <span className="star"></span>
                          ))}
                        </span>
                      </li>
                    );
                  })}
                </>
              )
            );
          })
        ) : (
          <Typography variant="overline"> {t("no_skillset")}</Typography>
        )}
      </Grid>
    </ul>
  );
}
