import React, { useState } from "react";
import { t } from "./utils/i18n";
import Routes from "./routes/index";
import { Box, Typography, Link, ThemeProvider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Services
import AuthService from "./services/auth.service";
import AppService from "./services/app.service";

// Context
import { AppContext } from "./context/app";
import { AuthContext } from "./context/auth";

// Components
import MediaFortaDialog from "./components/MediaFortaDialog";

// assets
import "./assets/scss/app.scss";
import theme from "./themes/mediaforta";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.mediaforta.com/">
        {" "}
        Mediaforta{" "}
      </Link>
      {` ${new Date().getFullYear()}.`}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  box: {
    "& > *": {
      padding: "0 15px",
    },
  },
  footer: {
    position: "relative",
    // zIndex: 999,
    padding: 5,
    backgroundColor: "#f5f5f5",
    marginTop: 20,
    "& a": {
      color: "#041e7a",
    },
  },
}));

function App() {
  const [dialog, setDialog] = React.useState(false);
  const token = AuthService.getCurrentUser();
  const [user, setUser] = useState(token);
  const [redirect, setRedirect] = useState(false);
  const setResponseDialog = (message) => setDialog(message);
  const [candidatesFilterResult, setCandidatesFilterResult] = useState([]);
  const classes = useStyles();

  const logout = () => {
    setRedirect(window.location.pathname);
    AuthService.logout();
    setUser(false);
    window.location = "/";
  };

  const [languages, setLanguages] = React.useState(false);
  React.useEffect(() => {
    if (!languages)
      AppService.languages().then((response) =>
        setLanguages(
          response.records.map(({ label, ...props }) => ({
            ...props,
            label: t(label),
          }))
        )
      );
    // eslint-disable-next-line
  }, []);

  if (!languages) return null;
  return (
    <AuthContext.Provider value={{ user, setUser, logout }}>
      <ThemeProvider theme={theme}>
        <AppContext.Provider
          value={{
            dialog,
            setDialog,
            setResponseDialog,
            redirect,
            languages,
            candidatesFilterResult,
            setCandidatesFilterResult,
          }}
        >
          <div className="App">
            <Routes />
            <Box className={classes.footer}>
              <Box
                mt={1}
                mb={2}
                display="flex"
                justifyContent="center"
                className={classes.box}
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                >
                  {t("privacy", true)}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                >
                  {t("cookie_policy", true)}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                >
                  {t("terms_and_conditions", true)}
                </Typography>
              </Box>
              <Copyright />
            </Box>
            {dialog && (
              <MediaFortaDialog data={{ ...dialog, setResponseDialog }} />
            )}
          </div>
        </AppContext.Provider>
      </ThemeProvider>
    </AuthContext.Provider>
  );
}

export default App;
