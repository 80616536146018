import React from "react";
import config from "../config";
import { Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Services
import AuthService from "../services/auth.service";

const { API_URL } = config.mediaforta;

const useStyles = makeStyles(() => ({
  avatar: {
    width: 110,
    height: 110,
    borderRadius: 0,
    border: "2px solid",
    margin: "28px !important",
  },
}));

export default function PortfolioImage({
  candidate,
  fileId,
  classes = {},
  style = {},
  isLink = false,
}) {
  const styles = useStyles();
  classes = { ...styles, ...classes };
  const user = AuthService.getCurrentUser() || {};
  const [avatar, setAvatar] = React.useState(false);

  React.useEffect(() => {
    if (fileId) {
      handleAction();
    }
    // eslint-disable-next-line
  }, [fileId]);

  const handleAction = async () => {
    const result = await fetch(
      `${API_URL}website/candidates/${candidate._id}/file/${fileId}`,
      {
        headers: {
          Authorization: "Bearer " + user.token,
          Prefer: "count=exact",
        },
      }
    );

    if (result.status !== 200) return;
    const blob = await result.blob();
    setAvatar(window.URL.createObjectURL(blob));
  };

  return (
    <>
      {/* eslint-disable-next-line*/}
      {!!isLink ? (
        <a
          href={avatar ? avatar : ""}
          className="blue-button no-arrow"
          style={{ ...style }}
        >
          download
        </a>
      ) : (
        <Avatar
          src={avatar ? avatar : ""}
          className={classes.avatar}
          style={{ ...style }}
        >
          {" "}
          U{" "}
        </Avatar>
      )}
    </>
  );
}
