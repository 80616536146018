import React from "react";
import { t } from "../utils/i18n";
import { useHistory, matchPath, useLocation, Redirect } from "react-router-dom";

// Context
import { useApp } from "../context/app";
import { useAuth } from "../context/auth";
import { useUser } from "../context/user";

import { CandidateContext } from "../context/candidate";

// Services
import CandidatesService from "../services/candidates.service";
import JobsService from "../services/jobs.service";
import AuthService from "../services/auth.service";

const requiredFields = [
  "street",
  "number",
  "postal",
  "city",
  "country",
  "phone",
];

export const fetchCandidate = async (id) => CandidatesService.get(id);
function CandidateContextRoute({ children }) {
  const history = useHistory();
  const location = useLocation();
  const { role = {}, isRecruiter } = useUser();
  const {
    user: {
      user: { jobId },
    },
  } = useAuth();
  const { setResponseDialog } = useApp();
  const [candidate, setCandidate] = React.useState(false);
  const [applying, setApplying] = React.useState(false);
  const [candidateFilter, setCandidateFilter] = React.useState(false);

  const profileIncomplete = () => {
    return requiredFields.reduce((memo, field) => {
      if (!candidate[field]) memo = true;
      return memo;
    }, false);
  };

  const checkProfile = (candidate) => {
    const incomplete = profileIncomplete();
    const { pathname } = history.location;
    const isJobApplication =
      matchPath(pathname, {
        path: "/jobs/apply/:jobId?",
      }) ||
      matchPath(pathname, {
        path: "/apply/:campaignId/:jobId?",
      });

    if (!!isJobApplication) return false;
    if (pathname === "/complete" && !incomplete) return "/profile";
    if (
      pathname !== "/complete" &&
      !pathname.includes("jobs/apply") &&
      candidate &&
      !isRecruiter() &&
      incomplete
    )
      return "/complete";
    return false;
  };

  const match = matchPath(history.location.pathname, {
    path: "/candidates/:candidateId?",
  });

  const candidateId =
    role.type === "candidate"
      ? role.candidateId
      : match
      ? match.params.candidateId
      : false;

  React.useEffect(() => {
    if (candidateId && (!candidate || candidateId !== candidate._id)) {
      fetchCandidate(candidateId).then(setCandidate).catch();
    }
    // eslint-disable-next-line
  }, [candidateId, match, location]);

  const updateCandidate = (candidate, cb) => {
    CandidatesService.patch(candidate._id, candidate)
      .then(({ data = candidate }) => {
        setCandidate(data);
        if (data.language && !isRecruiter())
          AuthService.updateUserLanguage(data.language);
        const redirect = checkProfile(data);
        if (redirect) return history.replace(redirect);
        else if (cb) cb(data);
      })
      .catch();
  };

  const deleteCandidate = () => {
    setResponseDialog({
      title: t("are_you_sure"),
      message: t("sure_remove_candidate"),
      onConfirm: () => CandidatesService.delete(candidate._id).then(),
    });
  };

  if (!isRecruiter()) {
    const redirect = checkProfile(candidate);
    if (redirect) return <Redirect to={redirect} />;
  }

  const refreshCandidate = () =>
    fetchCandidate(candidateId).then(setCandidate).catch();

  if (jobId && !applying && !profileIncomplete()) {
    setApplying(true);
    JobsService.apply({ jobId, candidateId }).then(() => {
      AuthService.removeJobId();
      setApplying(false);
    });
  }

  return (
    <CandidateContext.Provider
      value={{
        candidate,
        setCandidate,
        updateCandidate,
        deleteCandidate,
        profileIncomplete,
        refreshCandidate,
      }}
    >
      {children}
    </CandidateContext.Provider>
  );
}

export default CandidateContextRoute;
