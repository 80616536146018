import React, { useEffect } from "react";
import AppService from "../../services/app.service";
import moment from "moment";
import AuthenticatedAvatar from "../AuthenticatedAvatar";
import countries from "../../assets/countries/CountryList";
import { getLanguageList } from "../../assets/languages/LanguageList";
import CandidatesService from "../../services/candidates.service";
import PortfolioImage from "../PortfolioImage";
import { useApp } from "../../context/app";
import ResumeCandidateSkills from "./ResumeCandidateSkills";

import "./style.prefix.scss";

const dateFormat = "MM/YYYY";

const hireCandidate = async (id, contact) =>
  CandidatesService.hire(id, contact);

const Resume = ({ candidate }) => {
  const { setResponseDialog } = useApp();
  const [files, setFiles] = React.useState(candidate.candidatefiles || []);
  const [languages, setLanguages] = React.useState(null);

  useEffect(() => {
    const html = document.getElementsByTagName("html");
    html[0].classList = "resume";
    // Specify how to clean up after this effect:
    return function cleanup() {
      const html = document.getElementsByTagName("html");
      html[0].classList = "";
    };
  });

  React.useEffect(() => {
    if (!languages) {
      AppService.languages().then(setLanguages);
    }
    // eslint-disable-next-line
  }, []);

  const workExperience = candidate.candidateworkexperiences.sort((a) =>
    new Date(a.from).getTime()
  );
  const diplomas = candidate.candidatediplomas.sort((a) =>
    new Date(a.from).getTime()
  );

  const unavailable = candidate.candidateInfo?.unavailable;
  const available =
    !unavailable || !unavailable.to
      ? "available"
      : `available after ${unavailable.to}`;

  const getCountry = (countryCode) =>
    countries.find((c) => c.code === countryCode).label;
  const getLanguage = (motherTongue = "NL") =>
    getLanguageList().find((l) => l.code === motherTongue)?.name ||
    "Nederlands";

  const isDesigner = () =>
    candidate.userfunction &&
    !!["Designer", "copywriter", "translator"].includes(
      candidate.userfunction[0].name
    );

  return (
    <div className="page-template page-template-page-profiel page-template-page-profiel-php page page-id-11958 logged-in lang-nl has-banner">
      <main className="people-style">
        <header className="page-header-holder">
          <div className="wrap">
            <div className="page-header-profile-wrapper">
              <div className="left-side">
                <div className="profile-pic">
                  <AuthenticatedAvatar
                    width={280}
                    height={280}
                    candidate={candidate}
                    avatarId={candidate.avatar}
                  />
                </div>
              </div>

              <div className="right-side">
                <h1>{candidate.firstname}</h1>

                <ul className="functie">
                  <li>{candidate.userfunction[0].name}</li>
                </ul>

                <div className="profile-tekst-holder">
                  <p>{candidate.resumeSummary}</p>
                </div>

                <ResumeCandidateSkills candidate={candidate} />
              </div>
            </div>
          </div>
        </header>
        <div className="bg-beige has-availability-bg">
          <div className="wrap">
            <div className="uk-text-center">
              <h2>{candidate.firstname} is available</h2>
              {available ? (
                <a
                  href="https://www.mediaforta.com/en/hire-a-copywriter/"
                  className="blue-button"
                >
                  Work with {candidate.firstname}
                </a>
              ) : (
                ""
              )}
            </div>

            <div className="profile-details-holder bg-white">
              <section className="profile-details">
                <div className="uk-grid">
                  <div className="uk-width-1-3@s">
                    <h2 className="has-icon icon-location">Current location</h2>

                    <div className="tekst-holder-profile">
                      <p>{getCountry(candidate.country)}</p>
                    </div>
                  </div>
                  <div className="uk-width-1-3@s">
                    <h2 className="has-icon icon-language">Mother tongue</h2>

                    <div className="tekst-holder-profile">
                      <p>{getLanguage(candidate.language)}</p>
                    </div>
                  </div>
                  <div className="uk-width-1-3@s">
                    <h2 className="has-icon icon-paper">Years of experience</h2>
                    {candidate.language.skillSet &&
                    candidate.candidateInfo.skillSet.yearsExperience ? (
                      <div className="tekst-holder-profile">
                        <p>
                          {candidate.candidateInfo.skillSet.yearsExperience +
                            " years experience"}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}{" "}
                  </div>
                  <div className="uk-width-1-1">
                    <h2 className="has-icon icon-speech">About me</h2>

                    <div className="tekst-holder-profile">
                      <p>{candidate.info}</p>
                    </div>
                  </div>
                  <div className="uk-width-1-2@s">
                    <h2 className="has-icon icon-heart">
                      Preferred project(s)
                    </h2>

                    <div className="tekst-holder-profile">
                      <p>{candidate.candidateInfo.projectPreferences}</p>
                    </div>
                  </div>
                  <div className="uk-width-1-2@s">
                    <h2 className="has-icon icon-top">Top projects</h2>

                    <div className="tekst-holder-profile">
                      <p>{candidate.candidateInfo.topProject}</p>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="cv-details-holder bg-white">
              <section className="cv-details">
                <section className="cv-werkervaring">
                  <h2 className="has-icon has-icon-work">Work experience</h2>

                  <div className="cv-werkervaring-item">
                    {workExperience.map((Experiences) => (
                      <div className="cv-werkervaring-item-item">
                        <h3>{Experiences.function}</h3>
                        <p>
                          {moment(Experiences.from).format(dateFormat)}
                          &nbsp;-&nbsp;
                          {moment(Experiences.to).format(dateFormat)}
                        </p>
                        <p>{Experiences.company}</p>
                      </div>
                    ))}
                  </div>
                </section>

                <section className="cv-werkervaring">
                  <h2 className="has-icon has-icon-education">Education</h2>

                  {diplomas.map((Education) => (
                    <div className="cv-werkervaring-item">
                      <h3>{Education.degree}</h3>
                      <p>
                        {moment(Education.from).format(dateFormat)}
                        &nbsp;-&nbsp;
                        {moment(Education.to).format(dateFormat)}
                      </p>
                      <p>{Education.institute}</p>
                    </div>
                  ))}
                </section>

                {!!isDesigner() &&
                  !!files.filter((file) => !!file.portfolio).length && (
                    <section className="cv-werkervaring">
                      <h2 className="has-icon has-icon-portfolio">Portfolio</h2>
                      {files
                        .filter((file) => !!file.portfolio)
                        .map((file) => {
                          return (
                            <div className="cv-werkervaring-item-item portfolio">
                              <h3>{file.name}</h3>
                              <div>
                                <PortfolioImage
                                  fileId={file._id}
                                  candidate={candidate}
                                  isLink={true}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </section>
                  )}
              </section>
            </div>

            <div className="uk-text-center">
              {available ? (
                <a
                  href="https://www.mediaforta.com/en/hire-a-copywriter/"
                  className="blue-button"
                >
                  Work with {candidate.firstname}
                </a>
              ) : (
                ""
              )}
            </div>
            <section className="block bg-beige block-hoe-werkt-het">
              <header className="block-tekst-header wrap">
                <div className="running-tekst">
                  <h2>How does it work?</h2>
                </div>
              </header>

              <div className="wrap">
                <div className="hoe-holder">
                  <div className="uk-grid uk-grid-collapse" uk-grid>
                    <div className="uk-width-1-3@m">
                      <div className="hoe-holder-item">
                        <div className="deco deco-production"></div>

                        <h4>Content team on-demand</h4>
                        <p>
                          Talk to one of our content recruiters. A content
                          expert will discuss the details of your project with
                          you.
                        </p>
                      </div>
                    </div>

                    <div className="uk-width-1-3@m">
                      <div className="hoe-holder-item">
                        <div className="deco deco-delivery"></div>

                        <h4>Meet</h4>
                        <p>
                          We’ll quickly propose the right talent for your
                          project.
                        </p>
                      </div>
                    </div>

                    <div className="uk-width-1-3@m">
                      <div className="hoe-holder-item">
                        <div className="deco deco-briefing"></div>

                        <h4>Match and collaborate</h4>
                        <p>
                          Start with a trial period to ensure you’re hiring the
                          perfect fit for your project.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="block block-selectie-proces bg-beige">
              <header className="block-tekst-header wrap">
                <div className="running-tekst">
                  <h2>Our selection process</h2>
                  <p>
                    We are a quality-first company. Mediaforta selects only the
                    best content creators from its network. Our effective
                    selection process ensures that you will be working with the
                    most talented content creators available.
                  </p>
                </div>
              </header>

              <div className="wrap">
                <section className="selectie-step selectie-step-30 selectie-step-explanation-right">
                  <h3>30%</h3>
                  <div className="explanation">
                    <p>
                      Within our network, we look for candidates with the
                      required native language and the necessary specialisations
                      and skills. We then refine the selection, to choose the
                      best candidates.
                    </p>

                    <img src="https://www.mediaforta.com/wp-content/themes/mfagency/library/images/people/arrow-transp.svg" />
                  </div>
                </section>

                <section className="selectie-step selectie-step-12">
                  <h3>12%</h3>
                  <div className="explanation">
                    <p>
                      In one-to-one, in-depth interviews, we verify the
                      candidate’s specialisations. We also build an accurate
                      picture of candidates' interests, drives and personality.
                    </p>

                    <img src="https://www.mediaforta.com/wp-content/themes/mfagency/library/images/people/arrow-transp.svg" />
                  </div>
                </section>

                <section className="selectie-step selectie-step-8 selectie-step-explanation-right">
                  <h3>8%</h3>
                  <div className="explanation">
                    <p>
                      In this phase, candidates complete several test projects,
                      based on the work the candidate is expected to do. The
                      tests reflect their knowledge, professionalism and
                      commitment.
                    </p>

                    <img src="https://www.mediaforta.com/wp-content/themes/mfagency/library/images/people/arrow-transp.svg" />
                  </div>
                </section>

                <section className="selectie-step-final">
                  <div className="circle circle-4"></div>
                  <div className="circle circle-3"></div>
                  <div className="circle circle-2"></div>
                  <div className="circle circle-1"></div>

                  <div className="holder">
                    <h3>5%</h3>

                    <div className="top-5">
                      5% best content creators from our international network
                    </div>

                    <div className="explanation">
                      <p>
                        Mediaforta has a unique global network of first-rate
                        content creators. Our network is home to copywriters,
                        translators, UX writers, video reporters, social media
                        managers, illustrators and designers and other
                        creatives—each with an exceptional professional
                        background. With our network, agency and software
                        platform – and the integrity that underpins them – we
                        help top companies make their content marketing a
                        success.
                      </p>
                    </div>

                    <div className="buttons-holder">
                      <a
                        href="https://www.mediaforta.com/en/hire-a-copywriter/"
                        target="_blank"
                        className="blue-button"
                      >
                        Strengthen your team
                      </a>

                      <a
                        href="https://www.mediaforta.com/en/network/"
                        className="with-arrow"
                      >
                        Register as a freelancer
                      </a>
                    </div>
                  </div>
                </section>
              </div>

              <div className="more-button-holder">
                <a href="#" rel="nofollow" className="blue-button plus-button">
                  Meer over ons selectieproces
                </a>
              </div>
            </section>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Resume;
