import React from "react";
import config from "../config";
import { Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Services
import AuthService from "../services/auth.service";

const { API_URL } = config.mediaforta;

export default function AuthenticatedAvatar({
  candidate,
  avatarId,
  classes = {},
  width = 50,
  height = 50,
}) {
  classes = { ...classes };
  const user = AuthService.getCurrentUser() || {};
  const [avatar, setAvatar] = React.useState(false);

  React.useEffect(() => {
    if (avatarId || candidate) {
      handleAction();
    }
    // eslint-disable-next-line
  }, [avatarId, candidate]);

  const handleAction = async () => {
    if (!candidate.avatar) return setAvatar(false);
    const result = await fetch(
      `${API_URL}website/candidates/${candidate._id}/file/${candidate.avatar}`,
      {
        headers: {
          Authorization: "Bearer " + user.token,
          Prefer: "count=exact",
        },
      }
    );

    if (result.status !== 200) return;
    const blob = await result.blob();
    setAvatar(window.URL.createObjectURL(blob));
  };

  return (
    <>
      {/* eslint-disable-next-line*/}
      <Avatar
        src={avatar ? avatar : "/avatar.png"}
        style={{ width, height, backgroundColor: "#fff" }}
      >
        {" "}
      </Avatar>
    </>
  );
}
