export const TRANSLATIONS_NL = {
  existing_user_title: "Heb je al een account?",
  existing_user_title_apply:
    "Heb je al een account? Meld je aan om je voor deze vacature te registreren.",
  new_user_title: "Ben je nieuw?",
  new_user_title_apply: "Ben je nieuw? Maak eerst een account aan.",
  edit_task: "bewerk taak",
  new_task: "nieuwe taak",
  all: "iedereen",
  active: "actief",
  unfinished: "nog niet klaar",
  finished: "klaar",
  forgot_password: "Wachtwoord vergeten?",
  choose_password: "Kies je nieuwe wachtwoord.",
  email_sent_title: "We hebben je een e-mail verzonden.",
  email_sent_info:
    "We sturen je een e‑mail waarmee je je registratie kan bevestigen.<br/>Je moet je e‑mailadres bevestigen om verder te gaan.",
  email_sent_spam: "Check zeker ook je ongewenste mail of spambox.",
  email_not_received: "Geen e-mail ontvangen?",
  resend: "verzend opnieuw",
  create_account: "maak account aan",
  hello: "Hallo",
  join_us:
    "Kom bij ons en maak kans om voor topbedrijven te <br/>werken die op zoek zijn naar kwalitatieve content.",
  account_details: "Accountgegevens",
  testimonial_century21:
    '"Mediaforta levert kwalitatieve content voor onze internationale B2B-doelgroep" <label>Kim R. COO <br/>Century21</label>',
  add_link: "voeg een link toe",
  personal_website: "persoonlijke website",
  fill_personal_website: "geef je persoonlijke website in",
  are_you_sure: "Ben je zeker?",
  confirm_remove_job: "Ben je zeker dat je deze job wil verwijderen?",
  remove_job_application:
    "Ben je zeker dat je deze jobsollicitatie wil verwijderen?",
  edit_job: "bewerk job",
  freelance_visibility: "Zichtbaarheid & Freelance Status",
  remove_job: "verwijder job",
  add_job: "voeg job toe",
  new_job: "nieuwe job",
  cancel: "annuleer",
  experience: "ervaring",
  add_work: "voeg werk toe",
  edit_experience: "bewerk ervaring",
  new_experience: "nieuwe ervaring",
  education: "opleiding",
  add_education: "voeg opleiding toe",
  edit_education: "bewerk diploma",
  new_education: "nieuw diploma",
  diplomas: "diploma’s",
  add_diploma: "voeg diploma toe",
  candidates: "kandidaten",
  personal_info: "Vertel iets meer over jezelf.",
  work_experience: "werkervaring",
  none: "geen",
  edit_candidate: "bewerk kandidaat",
  delete_candidate: "verwijder kandidaat",
  edit_profile: "bewerk je profiel",
  back_candidates: "terug naar alle kandidaten",
  actions: "acties",
  missing_field: "ontbrekend veld",
  field_required: "{{veld}} is verplicht",
  required: "verplicht",
  welcome_back: "Welkom terug...",
  activation_info:
    "We hebben nog een aantal gegevens van je nodig om een goede match te kunnen vormen tussen jou en onze opdrachtgevers.",
  complete_info:
    "Bedankt dat je jouw registratie verder wil afwerken. Enkel wanneer je registratie is afgewerkt kunnen wij jou mogelijks ook jobs aanbieden.",
  get_started: "aan de slag",
  confirm_remove_calendar_item:
    "Ben je zeker dat je dit kalenderonderwerp wil verwijderen?",
  great_success: "Veel succes!",
  calendar_item_removed: "kalenderonderwerp verwijderd",
  calendar_item_added: "kalenderonderwerp toegevoegd",
  calendar_item_updated: "kalenderonderwerp geüpdatet",
  sure_remove_score: "Ben je zeker dat je deze score wil verwijderen?",
  sure_remove_test: "Ben je zeker dat je deze test wil verwijderen?",
  sure_generate_qr: "Are you sure you want to regenarate 2fa code",
  search_tests: "zoektesten",
  remark_required: "opmerking is verplicht",
  add_score: "voeg score toe",
  previous: "terug",
  edit_score: "bewerk score",
  test_updated: "test geüpdatet",
  test_created: "Test gecreëerd",
  name: "naam",
  firstname: "voornaam",
  lastname: "familienaam",
  add_file: "voeg bestand toe",
  edit_test: "bewerk test",
  new_test: "nieuwe test",
  add_test: "voeg test toe",
  sure_remove_task: "Ben je zeker dat je deze taak wil verwijderen?",
  type: "soort",
  due_date: "datum van oplevering",
  assigned_to: "toegewezen aan",
  tasks: "taken",
  search_tasks: "zoek taken",
  todo: "te doen",
  call: "bel",
  title_short: "te korte titel",
  min_chars: "Gelieve minstens {{aantal}} karakters in te vullen.",
  task_updated: "taak geüpdatet",
  task_created: "taak gecreëerd",
  title: "titel",
  assign_to: "wijs toe aan",
  description: "beschrijving",
  add_task: "voeg taak toe",
  add_tag: "voeg tag toe",
  email: "e-mail",
  password: "wachtwoord",
  login: "inloggen",
  email_forgot_password_link: "Wachtwoord vergeten? Krijg een link via e-mail.",
  reset_failed: "Gebruiker niet gevonden.",
  search: "zoek",
  search_candidates: "zoek kandidaten",
  invalid_image_url: "Gelieve een geldige afbeeldings-url in te vullen.",
  image_url: "afbeeldings-url",
  passwords_no_match: "wachtwoorden komen niet overeen",
  confirm_password: "herhaal je wachtwoord",
  save_password: "wachtwoord opslaan",
  dutch: "Nederlands",
  french: "Français",
  english: "English",
  i_am: "Ik ben een",
  my_lang: "taal",
  lets_go: "aan de slag",
  recruiter: "rekruteerder",
  sure_remove_note: "Ben je zeker dat je deze notitie wil verwijderen?",
  search_notes: "zoek notities",
  score_low: "te lage score",
  score_high: "te hoge score",
  edit_note: "bewerk notitie",
  new_note: "nieuwe notitie",
  add_note: "voeg notitie toe",
  note_created: "notitie gecreëerd",
  note_updated: "notitie geüpdatet",
  jobs: "jobs",
  link_candidate_job: "Koppel kandidaat aan job",
  agenda: "agenda",
  stats: "statistieken",
  add_filter: "voeg filter toe",
  logout: "log uit",
  profile: "profiel",
  work: "werk",
  links: "links",
  something_wrong: "Er is iets fout gegaan.",
  contact_recruiter: "Contacteer ons via recruiter@mediaforta.be, alsjeblieft.",
  future: "toekomst",
  ongoing: "voortdurend",
  search_jobs: "zoek jobs",
  filter_status: "filter status",
  job_title: "Jobtitel",
  client: "klant",
  select_future_date: "Gelieve een datum in de toekomst te selecteren.",
  function: "functie",
  start_date: "begindatum",
  end_date: "einddatum",
  language: "taal",
  salary: "loon",
  location: "locatie",
  type_of_employment: "soort werk",
  level_of_applicant: "niveau van de sollicitant",
  time: "tijd",
  work_for: "Voor wie ga je werken?",
  job_introduction: "Introductie jobomschrijving",
  look_for: "Wie zoeken we?",
  what_offer: "Wat bieden we aan?",
  save: "bewaar",
  tags: "tags",
  add: "voeg toe",
  past_image_url: "kopieer de afbeeldings-url",
  search_emails: "zoek mails",
  from: "van",
  to: "aan",
  mail: "mail",
  message_to_short: "te kort bericht",
  no_candidate_found: "geen kandidaat gevonden",
  scheduled_send: "verzending gepland",
  subject: "onderwerp",
  message: "bericht",
  send: "verzonden",
  invalid_email: "ongeldige mail",
  no_tags_assigned: "geen tags toegekend",
  no_source_assigned: "geen bron toegekend",
  add_source: "voeg bron toe",
  new_applicant: "nieuwe sollicitant",
  test: "test",
  new_interview: "nieuw interview",
  live_interview: "live interview",
  offer: "aanbod",
  archived: "gearchiveerd",
  archive: "archief",
  status: "status",
  specialisation: "specialisatie",
  specialisations: "specialisaties",
  evaluation: "evaluatie",
  remove_skill: "Verwijder vaardigheid",
  remove_skill_item: "Verwijder vaardigheid item",
  add_skill: "voeg vaardigheid toe",
  very_good: "++ heel goed",
  good: "+ goed",
  bad: "- slecht",
  very_bad: "— heel slecht",
  untill: "tot",
  createdAt: "aangemaakt op",
  createdAt_from: "aangemaakt van",
  createdAt_to: "aangemaakt tot",
  modifiedAt: "aangepast op",
  unavailable: "niet beschikbaar",
  modifiedByRecruiterAt: "aangepast door rekruteerder op",
  available_from: "beschikbaar van",
  available_to: "beschikbaar tot",
  interview_created: "interview gecreëerd",
  interview_updated: "interview geüpdatet",
  interview_removed: "interview verwijderd",
  no_links_assigned: "geen links toegekend",
  search_interviews: "zoek interviews",
  start_interview: "start interview",
  interview_by: "interview door",
  interview: "interview",
  sure_remove_interview: "Ben je zeker dat je dit interview wil verwijderen?",
  question: "vraag",
  new_question: "Nieuwe vraag",
  edit_question: "Pas vraag aan",
  sure_remove_question: "Ben je zeker dat je deze vraag wil verwijderen?",
  answer: "antwoord",
  remove_question: "verwijder vraag",
  remove: "verwijder",
  add_question: "voeg vraag toe",
  sure_remove_experience: "Ben je zeker dat je deze ervaring wil verwijderen?",
  company: "bedrijf",
  worked_as_freelancer: "gewerkt als freelancer",
  function_short: "functie te kort",
  company_short: "bedrijf te kort",
  add_experience: "voeg ervaring toe",
  new_email: "nieuwe e-mail",
  view_email: "bekijk e-mail",
  add_email: "voeg e-mail toe",
  sure_remove_degree: "Ben je zeker dat je deze graad wil verwijderen?",
  degree: "graad",
  university: "universiteit",
  country: "land",
  institute_to_short: "instelling te kort",
  degree_to_short: "graad te kort",
  edit_degree: "bewerk graad",
  add_degree: "voeg graad toe",
  full_profile: "volledig profiel",
  status_interview: "status interview",
  phone_interview: "telefonisch interview",
  add_candidate_link: "voeg link kandidaat toe",
  candidate_source: "bron kandidaat",
  street: "straat",
  number: "nummer",
  box: "postbus",
  postal: "postcode",
  city: "stad",
  province: "provincie",
  phone: "telefoon",
  skype: "Skype",
  sure_remove_file: "Ben je zeker dat je dit bestand wil verwijderen?",
  sure_archive_candidate: "Ben je zeker dat je deze kandidaat wil archiveren?",
  update_candidate: "bewerk kandidaat",
  update_profile: "bewaar je wijzigingen",
  attachments: "bijlages",
  no_attachments_assigned: "geen bijlages toegekend",
  no_job_applications_assigned: "geen jobsollicitaties toegekend",
  source: "bron",
  info: "info",
  sure_remove_candidate: "Ben je zeker dat je deze kandidaat wil verwijderen?",
  job_applications: "jobsollicitaties",
  pending: "in afwachting",
  rejected: "afgekeurd",
  successful: "succesvol",
  search_job_applications: "zoek jobsollicitaties",
  employer: "werkgever",
  application_date: "datum van sollicitatie",
  search_for_similar_jobs: "zoek gelijkaardige jobs",
  projects: "projecten",
  preferred_projects: "Welke projecten genieten je voorkeur?",
  add_skills: "Voeg je specialisaties toe.",
  skills: "specialisaties",
  skills_categories: "specialisatie categorieën",
  which_skills: "Wat zijn je specialisaties?",
  max_5_skills:
    'Kies maximaal vijf vaardigheden die het beste bij je passen, en klik dan op "volgende".',
  which_writer_skills: "Waarover kun je goed schrijven?",
  max_5_writer_skills:
    "Kies maximaal vijf onderwerpen waarin je schrijfervaring en expertise in hebt, en klik dan op 'volgende'.",
  find_skill: "Vind je niet wat je zoekt?",
  add_new_skill: "Voeg een nieuwe specialisatie toe.",
  next: "volgende",
  add_remark: "opmerking toevoegen",
  remark: "opmerking",
  selected_skills: "Jouw geselecteerde specialisaties:",
  manage_skills: "pas je specialisaties aan",
  existing: "bestaand",
  for_validation: "ter goedkeuring",
  add_specialisation: "voeg specialisatie toe",
  edit_specialisation: "bewerk specialisatie",
  new_specialisation: "nieuwe specialisatie",
  edit_specialisation_category: "bewerk specialisatiecategorie",
  new_specialisation_category: "nieuwe specialisatiecategorie",
  submitted_by: "ingediend door",
  submitted_on: "ingediend op",
  validation: "goedkeuring",
  category: "categorie",
  label: "label",
  no_pending_skills: "geen specialisaties in afwachting",
  see_candidates: "zie kandidaten",
  decline: "wijs af",
  approve: "keur goed",
  jobapplication_mail_body: "afgewezen jobsollicitatie mail standaardbericht",
  jobapplication_mail_subject:
    "afgewezen jobsollicitatie mail standaardonderwerp",
  jobapplication_popup_title: "afgewezen e-mail bericht",
  no_jobs_found: "geen jobs gevonden",
  fill_in_user_create_fields:
    "Gelieve alle velden in te vullen en ervoor te zorgen dat de e-mail uniek is.",
  personal_data: "gegevens",
  contact: "contact",
  project_preferences: "Welke projecten genieten je voorkeur?",
  top_project: "Op welk project ben je het meest trots?",
  work_as_freelancer: "Werk je als freelancer?",
  public_enabled:
    "Mediaforta mag mijn profiel delen met prospecten en klanten van Mediaforta",
  yes: "ja",
  no: "nee",
  avatar: "profielfoto",
  personal_details: "persoonlijke gegevens",
  professional_details: "professionele gegevens",
  years_experience: "jaren ervaring",
  native_language: "moedertaal",
  translations: "vertalingen",
  translate_from: "vertaal van",
  translate_to: "vertaal naar",
  add_language: "voeg taal toe",
  profile_picture: "profielfoto",
  choose_file: "kies bestand",
  rates: "prijzen",
  no_price_set: "Geen prijzen ingesteld",
  dayRate: "Dagprijs (in euro)",
  pricePerWord: "Prijs per woord (in euro)",
  pricePerHour: "Prijs per uur (in euro)",
  cv_resume: "samenvattend cv",
  portfolio: "Portfolio",
  registration_completed_title: "Bedankt om je te registreren!",
  registration_completed_sub:
    "We nemen zo snel mogelijk contact <br/> met je op voor de volgende stappen.",
  vacancies: "vacatures",
  goto_vacancies: "bekijk vacatures",
  blogposts: "blogposts",
  goto_blogposts: "bekijk blogposts",
  your_profile: "jouw profiel",
  goto_your_profile: "bekijk jouw profiel",
  no_translations: "geen vertalingen",
  read_more: "lees meer",
  please_select_one_skill: "Gelieve minimum 1 specialisatie te selecteren.",
  no_mothertongue: "geen moedertaal gekozen",
  no_skillset: "geen specialisaties gekozen",
  close_filter: "sluit filter",
  how_does_it_work: "Hoe werkt het?",
  how_does_it_work_info:
    "Mediaforta heeft een exclusief netwerk met het beste talent op het gebied van contentcreatie. Wij bieden toegang aan topbedrijven, een community van experten en middelen die jouw carrière kunnen versnellen.",
  activate_question: "Selectieprocedure",
  activate_question_info:
    "Als bedrijf waarvoor kwaliteit voorop staat, selecteert Mediaforta de beste contentcreators uit de kandidaten. Onze doeltreffende selectieprocedure zorgt ervoor dat onze klanten met de beste freelance contentcreators en contentmarketeers kunnen samenwerken.",
  activate_meet: "Krijg opdrachten",
  activate_meet_info:
    "Ben je geslaagd voor onze selectieprocedure, dan maak je deel uit van ons netwerk. Zoekt een opdrachtgever een contentcreator met jouw specialiteiten, dan stellen we jou voor. Finaal beslis jij of je de job aanneemt of niet.",
  activate_match: "Word correct betaald",
  activate_match_info:
    "Mediaforta is geen prijsspeler. Wij betalen onze freelance contentcreators een correcte en eerlijke vergoeding.",
  testimonial_ludovic:
    '"Mediaforta is prettig om mee samen te werken als freelancer. Heldere afspraken, to the point communicatie en je krijgt de kans om voor grote en beloftevolle ondernemingen te werken." <label>Ludovic <br/>Freelance copywriter</label>',
  privacy_check:
    'Door u te registreren bevestigt u dat u onze <a href="https://www.mediaforta.com/algemene-voorwaarden/" target="_blank">algemene voorwaarden</a>, het <a href="https://www.mediaforta.com/privacyverklaring-mediaforta/" target="_blank">privacy beleid</a>, en het <a href="https://www.mediaforta.com/cookie-policy/" target="_blank">cookiebeleid</a>, hebt gelezen en hiermee akkoord gaat.',
  newsletter_check:
    "Mis niets! Hou mij via e-mail op de hoogte van interessante jobs en inspirerende content.",
  trusted_by: "Vertrouwd door o.a.",
  copywriter: "Copywriter",
  uxcopywriter: "UX copywriter",
  which_ux_writer_skills: "Kies je specialisatie als UX-copywriter",
  translator: "Vertaler",
  contentmarketer: "Contentmarketeer",
  sure_remove_recruiter_role:
    "Weet u zeker dat u de rol van recruiter wilt verwijderen?",
  new_recruiter: "Nieuwe recruiter toevoegen",
  remove_account: "verwijder mijn account",
  why_remove_account:
    "Kan je nog even aangeven waarom je wilt stoppen met Mediaforta",
  keep_data_and_mail:
    "Mediaforta mag mijn gegevens nog bewaren en mij nog mailen",
  delete_data_and_mail: "Mediaforta mag mijn gegevens niet meer bewaren",
  choose_more: "Choose more",
  add_category: "Add category",
  change: "Wijzig",
  remove_category:
    "Ben je zeker dat je deze categorie wil verwijderen uit de huidige scorecard?",
  manage_items: "Manage items",
  manage: "Manage",
  search_or_add: "Search or add new",
  remove_score_card: "Ben je zeker dat je deze scorecard wil verwijderen?",
  at_least_one_price_required:
    "Gelieve een dagprijs of prijs per woord in te vullen",
  always_available: "Altijd beschikbaar",
  privacy:
    '<a target="_blank" href="https://www.mediaforta.com/privacyverklaring-mediaforta/">Privacy</a>',
  cookie_policy:
    '<a target="_blank" href="https://www.mediaforta.com/cookie-policy/">Cookie Policy</a>',
  terms_and_conditions:
    '<a target="_blank" href="https://www.mediaforta.com/algemene-voorwaarden/">Algemene voorwaarden</a>',
  Designer: "Grafisch vormgever",
  skills_stars:
    "Het aantal sterren geeft aan hoe bedreven je bent in deze vaardigheid.",
  digitalmarketer: "Digitaal marketeer",
  socialmediacontentcreator: "Social media contentcreator",
};
